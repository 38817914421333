import React from 'react'; // , { useEffect }
//import { useSelector } from 'react-redux';
import './style.scss';
const Home = () => {
    return (
      <div className="home">
        <br/>
        <h1 className='home-title'>WELCOME TO THE</h1>
        <h1 className='home-title'>API.NOSRANKING.COM'S</h1>
        <h1 className='home-title'>DOCUMENTATION</h1>
        <br/>
        <p className='home-text'>API.NOSRANKING.COM is an API that provides the ranking of Nostale players :</p>
        <p className='home-text'>Obtaining a player's ranking to display stats somewhere else (Ex: Discord) has always been complicated because GameForge doesn't offer an official API.</p>
        <p className='home-text'>That's why this API exist. We offer players and devs an easy way to display their public data on external platforms and social medias.</p>
        <p className='home-text'>In case of abuse, contact this website administrator by mailing to nosranking at truc-bidule.fr ! Enjoy !</p>
        <br/>
        <br/>
        <h2 className='home-title'>API RULES</h2>
        <p className='home-text'>This API is public, open-source and free. The number of requests that can be made per IP is limited to 30 per minute.</p>
        <p className='home-text'>We record the data that you ask us to fetch from the official nostale ranking website belonging to GameForge,</p>
        <p className='home-text'>in order to avoid spamming this ranking with untimely requests... In Other words, we strive to limit the number of parsing we perform. </p>
        <p className='home-text'>Any request that is considered as malformed, malicious or suspect will lead to your IP being banned from the API for 3 months. So don't do stupid things !</p>
        <br/>
        <br/>
        <h2 className='home-title'>DONATE AND HELP</h2>
        <p className='home-text'>I haven't thought about it. Maybe I'll add a way for you to gift us a coffee. We will see that later :D</p>
        <p className='home-text'>You can contribute to this project by opening issues on github...</p>
      </div>
    )
};

Home.propTypes = {};

export default Home;
