import React from 'react'; // , { useEffect }
//import { useSelector } from 'react-redux';
import './style.scss';
const Examples = () => {
  return (
    <div className="examples">
      <h1 className='examples-title' id='totopmarge'>Examples</h1>
      <br />
      <p className='examples-text'>Using NodeJS and the node-fetch library :</p>
      <pre className='examples-pre'>
        <code className='examples-pre-code'>
        {`
const fetch = require('node-fetch');
async function getRanking(player_name)
{
  const response = await fetch(
    'https://api.nosranking.com/v1/fr/?nick='+player_name
  );
  const data = await response.json();
  console.log(data);
}
getRanking('Earlam');
        `}
        </code>
      </pre>
        <h3 className='examples-title' id='totopmarge'>Response</h3>
      <div>
        <img className='examples-img' src='https://www.aht.li/3769653/nosrankinglogs.png' alt=''></img>
      </div>
    </div>
  );
};

Examples.propTypes = {};

export default Examples;
