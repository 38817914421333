import React from 'react'; // , { useEffect }
//import { useSelector } from 'react-redux';
import './style.scss';
const Params = () => {
  return (
    <div className="parameters">
      <h1 className='parameters-title' id='totopmarge'>URL PARAMETERS</h1>
      <br />
      <p className='parameters-text'>URL parameters (also known as “query strings”) are a way to structure additional information for a given URL. </p>
      <p className='parameters-text'>Parameters are added to the end of a URL after a ? symbol, and multiple parameters can be included when separated by the & symbol. </p>
      <p className='parameters-text'>This little API uses 2 parameters in its urls: nick and server. The <span style={{"textDecoration": "underline"}}>nick parameter</span> is mandatory. The server parameter is optional and must be a number.</p>
      <p className='parameters-text'>If no server is specified, the API will search for the first server in the linguistic zone, for example, Cosmos for French zone.</p>
      <br/>
      <br/>
      <div>
        <h3 className='parameters-title' id='totopmarge'>EN, US, INT</h3>
        <p className='parameters-text'>1 = EU-Dragonveil, 2 = US-Cylloan,</p>
        <p className='parameters-text'>3 = INT-Valehir, 4 = INT-Asgobas</p>
      </div>
      <br />
      <br/>
      <div>
        <h3 className='parameters-title'>DE</h3>
        <p className='parameters-text'>1 = DE-Alzanor</p>
      </div>
      <br />
      <br/>
      <div>
        <h3 className='parameters-title'>FR</h3>
        <p className='parameters-text'>1 = Cosmos</p>
      </div>
    </div>
  );
};

Params.propTypes = {};

export default Params;
