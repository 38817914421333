import React from 'react'; // , { useEffect }
//import { useSelector } from 'react-redux';
import './style.scss';
const Endpoints = () => {
  return (
    <div className="endpoints">
      <h1 className='endpoints-title' id='totopmarge'>API ENDPOINTS</h1>
      <br />
      <p className='endpoints-text'>On the first version, this API only provided players data from the ranking of the French server Cosmos.</p>
      <p className='endpoints-text'>We have chosen to extend this to the other language zones by uzing endpoints.</p>
      <p className='endpoints-text'>Each of these 3 endpoints groups the servers by language zone.</p>
      <br/>
      <br/>
      <div>
        <h3 className='endpoints-title' id='totopmarge'>EN, US, INT</h3>
        <p className='endpoints-text'>/v1/</p>
      </div>
      <br />
      <br/>
      <div>
        <h3 className='endpoints-title'>DE</h3>
        <p className='endpoints-text'>/v1/de/</p>
      </div>
      <br />
      <br/>
      <div>
        <h3 className='endpoints-title'>FR</h3>
        <p className='endpoints-text'>/v1/fr/</p>
      </div>
    </div>
  );
};

Endpoints.propTypes = {};

export default Endpoints;
