import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

//import { useSelector } from 'react-redux'
const Navbar = () => {

	//const isLogged = useSelector(state => state.home.user.logged);
	//const user = useSelector(state => state.home.user);
	return (
		<header className="navbar">
			<div className='navbar-logo'>
				<Link to="/" className="navbar-logo"><img src='https://www.aht.li/3769246/nr-192.png' className='navbar-logo-img' alt='' /></Link>
			</div>
			<div className='navbar-items'>

				<div className='navbar-items-item'>
					<Link to="/" className="navbar-items-item-link">GETTING STARTED</Link>
				</div>
				<div className='navbar-items-item'>
					<Link to="/endpoints" className="navbar-items-item-link">ENDPOINTS</Link>
				</div>
				<div className='navbar-items-item'>
					<Link to="/parameters" className="navbar-items-item-link">PARAMETERS</Link>
				</div>
				<div className='navbar-items-item'>
					<Link to="/examples" className="navbar-items-item-link">EXAMPLES</Link>
				</div>
				<div className='navbar-items-item'>
					<Link to="/about" className="navbar-items-item-link">ABOUT</Link>
				</div>
			</div>

		</header>
	);
};

Navbar.propTypes = {};
export default Navbar;
