import { connect } from 'react-redux';
import Home from '../../components/App/Home';
//import { getInfo, getConfig, select, updateConfig } from '../../actions';

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
 