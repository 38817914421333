import { Routes, Route, Navigate } from 'react-router-dom';
import React from 'react'; // , { useEffect }
import Home from '../../containers/Home';
import Navbar from './Navbar';
//import Footer from './Footer';
import Endpoints from './Endpoints';
import Parameters from './Parameters';
import Examples from './Examples';
import About from './About';

import './style.scss';
const App = () => {
  return (
    <div className="app" >
      <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/endpoints" element={<Endpoints />} />
          <Route exact path="/parameters" element={<Parameters />} />
          <Route exact path="/examples" element={<Examples />} />
          <Route exact path="/about" element={<About />} />
          <Route path="/*" element={<Navigate to="/" />} />
          <Route element={<Home />} />
        </Routes>
      {/*<Footer />*/}
    </div>
  );
};

App.propTypes = {};

export default App;