//import axios from 'axios';
//import stringSimilarity from 'string-similarity'
//import { GET_INFO, storeInfo, TOTO, storeToto, GET_LOGS, storeLogs, UPDATE_VOCAL, GET_FEEDBACK, storeFeedback, GET_TAGS, storeTags, SEARCH_BY_SIMILARITY, storeSimilarity } from '../actions';
//import apiBaseURL from './baseURL';
//axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

/*
const api = axios.create({
  baseURL: apiBaseURL,
});
*/
const homeMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    default:
      next(action);
  }
};

export default homeMiddleware;
