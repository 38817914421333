/*import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
*/

import React from 'react';
//import { render } from 'react-dom'; //
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'; 
import { PersistGate } from 'redux-persist/lib/integration/react';
import App from './containers/App';
import { BrowserRouter } from 'react-router-dom';
import { persistor, store } from '../src/store';
const rootReactElement = (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
const target = document.getElementById('root');
const root = createRoot(target);
root.render(rootReactElement);