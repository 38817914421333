import React from 'react'; // , { useEffect }
//import { useSelector } from 'react-redux';
import './style.scss';
const About = () => {
    return (
      <div className='about'>
        <p className='about-text'>Made by Earlam</p>
      </div>
    );
};

About.propTypes = {};

export default About;
