import { connect } from 'react-redux';
import App from '../../components/App';
//import { getInfo, getConfig, select, updateConfig } from '../../actions';
const mapStateToProps = (state) => ({
  accueil: state.home.accueil,
  user: state.home.user,
  guilds: state.home.guilds,
  selected: state.home.selected,

});
const mapDispatchToProps = (dispatch) => ({

});
 
export default connect(mapStateToProps, mapDispatchToProps)(App); 
